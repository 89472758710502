<template>
    <div class="department-list-wrapper">
        <div class="wrapper-left">
            <div class="department-search">
                <el-input
                    class="search-input"
                    v-model="searchParams.name"
                    placeholder="输入部门名称/简称"
                    @change="getDepartmentList"
                ></el-input>
                <el-popover
                    placement="top-start"
                    popper-class="team-member-popover"
                    v-model="isShowTeamSelect"
                    width="700"
                    trigger="click"
                >
                    <el-input
                        slot="reference"
                        :readonly="true"
                        class="search-input has-team-select"
                        v-model="creators">
                        <template slot="prepend">创建者</template>
                    </el-input>
                    <div class="team-select-box">
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :visible="isShowTeamSelect"
                            :selected="searchParams.creatorIds"
                            @item-select-cb="membersSelect"
                        ></team-member-select>
                    </div>
                </el-popover>

                <el-button
                    class="create-department-btn"
                    type="primary"
                    @click="dispatch('App', 'createDepartment', {
                        customerId: customerId,
                        customerName: customerName
                    })">
                    创建组织架构
                </el-button>
            </div>
            <div
                class="department-list"
                v-loading="loading">
                <department-item
                    v-for="department in departmentList"
                    :key="department.id"
                    :department="department"
                    :customerId="customerId"
                    :hasCurrentCustomerAuditPermission="hasCurrentCustomerAuditPermission"
                    @department-delete="handleDeleteShow"
                ></department-item>
                <el-pagination
                    class="pagination-common-justify"
                    :current-page="pager.current"
                    layout="prev, pager, next, slot, total, sizes"
                    :total="pager.total"
                    v-if="departmentList.length"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                    <span class="pagination-text">
                        <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
                <div
                    class="empty-data"
                    v-if="departmentList.length === 0">
                    <span class="empty-img"></span>
                    <p class="empty-text">暂无数据</p>
                </div>
            </div>
        </div>
        <!-- <div class="wrapper-right">
            <el-button
                class="create-department-btn"
                type="primary"
                @click="dispatch('App', 'createDepartment', {
                    customerId: customerId,
                    customerName: customerName
                })">
                创建组织架构
            </el-button>
        </div> -->
    </div>
</template>

<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import DepartmentItem from '../component/department-item.vue';
import DepartmentService from '#/js/service/departmentService.js';
import emitter from '#/js/mixins/emitter.js';
export default {
    components: {
        TeamMemberSelect,
        DepartmentItem
    },
    mixins: [emitter],
    props: {
        customerId: String,
        customerName: String
    },
    data() {
        return {
            searchParams: {
                name: "",
                creatorIds: []
            },
            creators: "全部成员",
            isShowTeamSelect: false,
            teamSelect: "",
            memberSelectList: [],
            loading: false,
            departmentList: [],
            groupVersion: '',
            pagerJump: 0,
            pager: {
                current: 1,
                size: 10,
                total: 1
            },
        }
    },
    computed: {
        hasCurrentCustomerAuditPermission() {
            let result = false;
            let auditCustomerList = this.$store.state.departmentAudit.customerList || [];
            if(auditCustomerList.length > 0) {
                auditCustomerList.some(item => {
                    if(item.id == this.customerId) {
                        result = true;
                        return true;
                    }
                })
            }
            return result;
        }
    },
    mounted() {
        this.teamSelect = document.querySelector(".team-select-box");
        this.getDepartmentList();
    },
    methods: {
        getDepartmentList(isSlient) {
            this.loading = true;
            let params = Object.assign({
                customerId: this.customerId
            }, this.searchParams);
            if(!isSlient) {
                this.pager.current = 1;
            }
            params.take = this.pager.size;
            params.start = (this.pager.current - 1) * params.take;
            DepartmentService.getCustomerDepartments(params)
            .then(res => {
                this.loading = false;
                this.departmentList = res.customerDepartmentList;
                this.groupVersion = res.groupVersion;
                this.pager.total = res.total;
                if(isSlient) {
                    this.$el.querySelector('.department-search').scrollIntoView();
                }
            }).catch(err => {
                this.loading = false;
                console.log(err);
            })
        },
        membersSelect(value, text) {
            this.memberSelectList = value;
            this.creators = text;
            this.searchParams.creatorIds = this.memberSelectList;
            this.isShowTeamSelect = false;
            this.getDepartmentList();
        },
        hideTeamSelect() {
            this.isShowTeamSelect = false;
        },
        handleDeleteShow(department) {
            this.$confirm("删除组织架构后，将无法恢复，确认是否要删除?", `正在删除${department.name}`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                DepartmentService.deleteDepartment({
                    id: department.id,
                    groupVersion: this.groupVersion
                }).then(res => {
                    this.loading = false;
                    shortTips('删除成功!')
                    this.getDepartmentList(true);
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                })
            });
        },
        handleCurrentChange(val) {
            this.pager.current = val;
            this.getDepartmentList(true);
        },
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.getDepartmentList(true);
        },
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.pager.total / this.pager.size);
            if(currentPager > 0 && currentPager <= currentPageTotal){
                this.handleCurrentChange(currentPager)
            }
        },
    }
}
</script>

<style lang="scss" scope>
    .department-list-wrapper {
        position: relative;
        // display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        .wrapper-left {
            // width: 960px;
            background-color: #ffffff;
            border-radius: 8px;
        }
        .wrapper-right {
            width: 168px;
        }
        .department-search {
            height: 64px;
            padding-left: 30px;
            display: flex;
            align-items: center;
            // background-color: #eee;
            border-bottom: 1px solid #eee;
            .create-department-btn {
                width: 150px;
                height: 40px;
                line-height: 40px;
                background: #38BC9D;
                border-radius: 4px;
            }
        }
        .search-input {
            // width: 285px;
            width: 408px;
            &:nth-of-type(1) {
                // margin-right: 190px;
                margin-right: 20px;
            }
            &.has-team-select {
                .el-input-group__prepend {
                    background-color: #fff;
                    color: #000;
                }
            }
        }
        .department-list {
            padding-bottom: 30px;
            margin: 40px 20px 0;
            // border-top: 1px solid #ddd;
            min-height: 200px;
        }
        .empty-data {
            margin: 10px 0 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 150px;
                height: 150px;
            }
            .empty-text {
                line-height: 20px;
                margin-bottom: 0px;
            }
        }
    }
</style>