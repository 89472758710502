var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "department-list-wrapper" }, [
    _c("div", { staticClass: "wrapper-left" }, [
      _c(
        "div",
        { staticClass: "department-search" },
        [
          _c("el-input", {
            staticClass: "search-input",
            attrs: { placeholder: "输入部门名称/简称" },
            on: { change: _vm.getDepartmentList },
            model: {
              value: _vm.searchParams.name,
              callback: function ($$v) {
                _vm.$set(_vm.searchParams, "name", $$v)
              },
              expression: "searchParams.name",
            },
          }),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "top-start",
                "popper-class": "team-member-popover",
                width: "700",
                trigger: "click",
              },
              model: {
                value: _vm.isShowTeamSelect,
                callback: function ($$v) {
                  _vm.isShowTeamSelect = $$v
                },
                expression: "isShowTeamSelect",
              },
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "search-input has-team-select",
                  attrs: { slot: "reference", readonly: true },
                  slot: "reference",
                  model: {
                    value: _vm.creators,
                    callback: function ($$v) {
                      _vm.creators = $$v
                    },
                    expression: "creators",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._v("创建者")])],
                2
              ),
              _c(
                "div",
                { staticClass: "team-select-box" },
                [
                  _c("team-member-select", {
                    ref: "teamSelect",
                    attrs: {
                      isAllMembers: true,
                      isShowDimission: false,
                      visible: _vm.isShowTeamSelect,
                      selected: _vm.searchParams.creatorIds,
                    },
                    on: { "item-select-cb": _vm.membersSelect },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "create-department-btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dispatch("App", "createDepartment", {
                    customerId: _vm.customerId,
                    customerName: _vm.customerName,
                  })
                },
              },
            },
            [_vm._v("\n                创建组织架构\n            ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "department-list",
        },
        [
          _vm._l(_vm.departmentList, function (department) {
            return _c("department-item", {
              key: department.id,
              attrs: {
                department: department,
                customerId: _vm.customerId,
                hasCurrentCustomerAuditPermission:
                  _vm.hasCurrentCustomerAuditPermission,
              },
              on: { "department-delete": _vm.handleDeleteShow },
            })
          }),
          _vm.departmentList.length
            ? _c(
                "el-pagination",
                {
                  staticClass: "pagination-common-justify",
                  attrs: {
                    "current-page": _vm.pager.current,
                    layout: "prev, pager, next, slot, total, sizes",
                    total: _vm.pager.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.departmentList.length === 0
            ? _c("div", { staticClass: "empty-data" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-text" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }