<template>
    <div class="department-item">
        <div class="department-info">
            <!-- :href="`/Headhunting/MyCompany.html#/Customer/${customerId}/department/${department.id}`" -->
            <a
                class="department-info-name"
                :href="`/#/Customer/${customerId}/department/${department.id}`"
                target="_blank">
                {{department.name}}
            </a>
            <!-- <br> -->
            <span
                class="department-info-parent"
                :title="department.path"
            >{{department.path | overLongFilter}}</span>
        </div>
        <div class="department-create">
            <avatar
                size="md"
                placement="top"
                class="department-create-creator"
                :src="department.avatar"
                :userId="department.creatorId"
            ></avatar>
            <span class="department-create-time">
                {{department.created | timeFilter}} 发布
            </span>
        </div>
        <div class="department-operate">
            <!-- :href="`/Headhunting/MyCompany.html#/Customer/${customerId}/department/${department.id}`" -->
            <a
                class="text-operate-btn"
                :href="`/#/Customer/${customerId}/department/${department.id}`"
                target="_blank">
                查看
            </a>
            <span
                v-if="hasCurrentCustomerAuditPermission"
                class="text-operate-btn red department-operate-delete"
                @click="$emit('department-delete', department)">
                删除
            </span>
        </div>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import moment from 'moment';
export default {
    props: {
        customerId: String,
        hasCurrentCustomerAuditPermission: {
            type: Boolean,
            default: false
        },
        department: {
            type: Object,
            default() {
                return {
                    name: "",
                    path: "",
                    avatar: "",
                    creatorId: "",
                    created: ""
                }
            }
        }
    },
    components: {
        Avatar
    },
    filters: {
        overLongFilter(val) {
            let enc = new TextEncoder("utf-8");
            if(enc.encode(val).length > 129) {
                let firstParent = "",
                    lastParent = "";
                firstParent = val.slice(0, val.indexOf("-"));
                lastParent = val.slice(val.lastIndexOf("-"));
                return firstParent + "- ··· " + lastParent;
            }
            return val;
        },
        timeFilter(val) {
            return moment(val).format('YYYY-MM-DD');
        }
    }
}
</script>

<style lang="scss" scope>
.department-item {
    border-bottom: 1px solid #ddd;
    padding: 0 30px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        background-color: #f0f0f0;
        .department-operate {
            visibility: visible;
        }
    }
    .department {
        &-info {
            // flex-grow: 1;
            width: 33%;
            line-height: 20px;
            display: flex;
            justify-content: space-between;
            &-name {
                display: inline-block;
                margin-bottom: 12px;
                font-size: 16px;
                color: #333;
                &:hover {
                    color: $primary;
                }
            }
            &-parent {
                display: inline-block;
                color: #999;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 600px;
            }
        }
        &-create {
            margin: 0 50px;
            // width: 116px;
            display: flex;
            // flex-direction: column;
            align-items: center;
            &-creator {
                // margin-bottom: 10px;
                margin-right: 10px;
            }
            &-time {
                font-size: 12px;
                color: #999;
            }
        }
        &-operate {
            visibility: hidden;
            font-size: 16px;
            white-space: nowrap;
            &-delete {
                margin-left: 20px;
            }
        }
    }
}
</style>