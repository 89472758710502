var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "department-item" }, [
    _c("div", { staticClass: "department-info" }, [
      _c(
        "a",
        {
          staticClass: "department-info-name",
          attrs: {
            href: `/#/Customer/${_vm.customerId}/department/${_vm.department.id}`,
            target: "_blank",
          },
        },
        [_vm._v("\n            " + _vm._s(_vm.department.name) + "\n        ")]
      ),
      _c(
        "span",
        {
          staticClass: "department-info-parent",
          attrs: { title: _vm.department.path },
        },
        [_vm._v(_vm._s(_vm._f("overLongFilter")(_vm.department.path)))]
      ),
    ]),
    _c(
      "div",
      { staticClass: "department-create" },
      [
        _c("avatar", {
          staticClass: "department-create-creator",
          attrs: {
            size: "md",
            placement: "top",
            src: _vm.department.avatar,
            userId: _vm.department.creatorId,
          },
        }),
        _c("span", { staticClass: "department-create-time" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("timeFilter")(_vm.department.created)) +
              " 发布\n        "
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "department-operate" }, [
      _c(
        "a",
        {
          staticClass: "text-operate-btn",
          attrs: {
            href: `/#/Customer/${_vm.customerId}/department/${_vm.department.id}`,
            target: "_blank",
          },
        },
        [_vm._v("\n            查看\n        ")]
      ),
      _vm.hasCurrentCustomerAuditPermission
        ? _c(
            "span",
            {
              staticClass: "text-operate-btn red department-operate-delete",
              on: {
                click: function ($event) {
                  return _vm.$emit("department-delete", _vm.department)
                },
              },
            },
            [_vm._v("\n            删除\n        ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }